import React, { FunctionComponent, memo } from 'react';
import classNames from 'classnames';

import style from './button.scss';
import { ButtonType } from 'types';

export const Button: FunctionComponent<ButtonType> = memo(
  ({
    onClick,
    children,
    type,
    variant,
    isDisabled,
    pull_right,
    border_only,
    cx,
  }) => {
    return (
      <button
        className={classNames(
          style.btn,

          {
            [style['btn--primary']]: variant === 'primary',
            [style['btn--secondary']]: variant === 'secondary',
            [style['btn--danger']]: variant === 'danger',
            [style['btn--warning']]: variant === 'warning',
            [style['btn--info']]: variant === 'info',
            [style['btn--link']]: variant === 'link',
            [style['btn--disabled']]: isDisabled,
            [style['btn--pull-right']]: pull_right,
            [style['btn--border-only']]: border_only,
          },
          cx
        )}
        type={type}
        disabled={isDisabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
);

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  isDisabled: false,
  border_only: false,
};
