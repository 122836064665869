import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconChevronLeft: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 10L3.5 6L7.5 2"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="square"
      />
    </svg>
  </span>
);
