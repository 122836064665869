import React, { FC, ComponentType } from 'react';
import { IconNames, IconProps } from './IconTypes';
import './Icon.scss';

import { IconGradeFilled } from './GradeFilled';
import { IconBin } from './Bin';
import { IconBookmark } from './Bookmark';
import { IconMenu } from './Menu';
import { IconCheck } from './Check';
import { IconCross } from './Cross';
import { IconDashboard } from './Dashboard';
import { IconDownload } from './Download';
import { IconFolderAdd } from './FolderAdd';
import { IconFile } from './File';
import { IconFolder } from './Folder';
import { IconGrade } from './Grade';
import { IconInbox } from './Inbox';
import { IconInfo } from './Info';
import { IconLoading } from './Loading';
import { IconRefresh } from './Refresh';
import { IconSearch } from './Search';
import { IconShare } from './Share';
import { IconUpload } from './Upload';
import { IconAdmin } from './Admin';
import { IconArchive } from './Archive';
import { IconChevronLeft } from './ChevronLeft';
import { IconChevronRight } from './ChevronRight';
import { IconEdit } from './Edit';
import { IconHelp } from './Help';
import { IconArrowDown } from './ArrowDown';
import { IconGrid } from './Grid';
import { IconList } from './List';
import { IconInfoFilled } from './InfoFilled';
import { IconHidePassword } from './HidePassword';
import { IconMore } from './More';
import { IconReport } from './Report';
import { IconSettings } from './Settings';
import { IconUnits } from './Units';
import { IconUsers } from './Users';
import { IconShowPassword } from './ShowPassword';
import { IconDocument } from './Document';
import { IconWhitelist } from './Whitelist';
import { IconHome } from './Home';

export const components: { [key in IconNames]: ComponentType } = {
  admin: IconAdmin,
  archive: IconArchive,
  'arrow-down': IconArrowDown,
  bin: IconBin,
  bookmark: IconBookmark,
  check: IconCheck,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  cross: IconCross,
  dashboard: IconDashboard,
  document: IconDocument,
  download: IconDownload,
  edit: IconEdit,
  file: IconFile,
  folder: IconFolder,
  'add-folder': IconFolderAdd,
  grade: IconGrade,
  'filled-grade': IconGradeFilled,
  grid: IconGrid,
  help: IconHelp,
  home: IconHome,
  list: IconList,
  inbox: IconInbox,
  info: IconInfo,
  'filled-info': IconInfoFilled,
  'hide-password': IconHidePassword,
  'show-password': IconShowPassword,
  loading: IconLoading,
  menu: IconMenu,
  more: IconMore,
  refresh: IconRefresh,
  report: IconReport,
  search: IconSearch,
  settings: IconSettings,
  share: IconShare,
  units: IconUnits,
  upload: IconUpload,
  users: IconUsers,
  whitelist: IconWhitelist,
};

export const Icon: FC<IconProps> = ({ name, ...props }) => {
  const TagName = components[name];

  if (typeof TagName !== 'undefined') {
    return <TagName {...props} />;
  } else {
    return null;
  }
};

Icon.defaultProps = {
  cx: [],
};

export {
  IconGradeFilled,
  IconBin,
  IconBookmark,
  IconMenu,
  IconCheck,
  IconCross,
  IconDashboard,
  IconDownload,
  IconFolderAdd,
  IconFile,
  IconFolder,
  IconGrade,
  IconInbox,
  IconInfo,
  IconLoading,
  IconRefresh,
  IconSearch,
  IconShare,
  IconUpload,
  IconAdmin,
  IconArchive,
  IconChevronLeft,
  IconChevronRight,
  IconEdit,
  IconHelp,
  IconArrowDown,
  IconGrid,
  IconList,
  IconInfoFilled,
  IconHidePassword,
  IconMore,
  IconReport,
  IconSettings,
  IconUnits,
  IconUsers,
  IconShowPassword,
  IconDocument,
  IconWhitelist,
  IconHome,
};
