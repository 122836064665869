import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconGradeFilled: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74787 5.60769L2.04088 5.20254L1.74787 5.60769L3.81298 7.1012L3.02013 9.52331C2.83942 10.0754 3.47223 10.5352 3.94144 10.1927L6 8.69016L8.05856 10.1927C8.52777 10.5352 9.16058 10.0754 8.97987 9.52331L8.50468 9.67886L8.97987 9.52331L8.18702 7.1012L10.2521 5.60769L9.95912 5.20254L10.2521 5.60769C10.7228 5.26728 10.4811 4.52336 9.90023 4.52463L7.35165 4.5302L6.5694 2.10464C6.3911 1.55179 5.6089 1.55178 5.4306 2.10465L4.64835 4.5302L2.09978 4.52463C1.51888 4.52336 1.27716 5.26727 1.74787 5.60769ZM6.38233 2.41158C6.38228 2.41174 6.38223 2.41189 6.38218 2.41204L6.38233 2.41158L6.11525 2.32545L6.38233 2.41158ZM7.44165 4.80926L7.44154 4.80892C7.44158 4.80903 7.44161 4.80915 7.44165 4.80926L7.05932 4.93256L7.44165 4.80926Z"
        fill={color ? color : 'currentColor'}
        stroke={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
