import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconInfo: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 4C5.5 3.72386 5.72386 3.5 6 3.5C6.27614 3.5 6.5 3.72386 6.5 4C6.5 4.27614 6.27614 4.5 6 4.5C5.72386 4.5 5.5 4.27614 5.5 4Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M6 5C6.27614 5 6.5 5.22386 6.5 5.5V8C6.5 8.27614 6.27614 8.5 6 8.5C5.72386 8.5 5.5 8.27614 5.5 8V5.5C5.5 5.22386 5.72386 5 6 5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
