import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconDocument: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 4.5C5.05228 4.5 5.5 4.05228 5.5 3.5C5.5 2.94772 5.05228 2.5 4.5 2.5C3.94772 2.5 3.5 2.94772 3.5 3.5C3.5 4.05228 3.94772 4.5 4.5 4.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M3.5 5.5C3.5 5.22386 3.72386 5 4 5H8C8.27614 5 8.5 5.22386 8.5 5.5C8.5 5.77614 8.27614 6 8 6H4C3.72386 6 3.5 5.77614 3.5 5.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M4 7C3.72386 7 3.5 7.22386 3.5 7.5C3.5 7.77614 3.72386 8 4 8H6.5C6.77614 8 7 7.77614 7 7.5C7 7.22386 6.77614 7 6.5 7H4Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3L8 1H3C2.44772 1 2 1.44772 2 2V10C2 10.5523 2.44772 11 3 11H9C9.55228 11 10 10.5523 10 10V3ZM7 4H9V10H3V2H7V4ZM8.58579 3L8 2.41421V3H8.58579Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
