import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconList: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4C1.77614 4 2 3.77614 2 3.5C2 3.22386 1.77614 3 1.5 3Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M1.5 5.5C1.22386 5.5 1 5.72386 1 6C1 6.27614 1.22386 6.5 1.5 6.5C1.77614 6.5 2 6.27614 2 6C2 5.72386 1.77614 5.5 1.5 5.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M1 8.5C1 8.22386 1.22386 8 1.5 8C1.77614 8 2 8.22386 2 8.5C2 8.77614 1.77614 9 1.5 9C1.22386 9 1 8.77614 1 8.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4H10.5C10.7761 4 11 3.77614 11 3.5C11 3.22386 10.7761 3 10.5 3H3.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M3 6C3 5.72386 3.22386 5.5 3.5 5.5H10.5C10.7761 5.5 11 5.72386 11 6C11 6.27614 10.7761 6.5 10.5 6.5H3.5C3.22386 6.5 3 6.27614 3 6Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M3.5 8C3.22386 8 3 8.22386 3 8.5C3 8.77614 3.22386 9 3.5 9H10.5C10.7761 9 11 8.77614 11 8.5C11 8.22386 10.7761 8 10.5 8H3.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
