import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconWhitelist: FC<IconType> = ({
  height = '12',
  width = '12',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65686 2.41422L9 3H3V9H9V5.8995L10 4.8995V9C10 9.55228 9.55228 10 9 10H3C2.44772 10 2 9.55228 2 9V3C2 2.44772 2.44772 2 3 2H9C9.29678 2 9.56336 2.12928 9.7465 2.3346C9.71544 2.35904 9.6855 2.38558 9.65686 2.41422Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M10.7175 3.47487C10.9128 3.27961 10.9128 2.96303 10.7175 2.76777C10.5223 2.5725 10.2057 2.5725 10.0104 2.76777L6.12133 6.65685L4.88389 5.41942C4.68863 5.22415 4.37205 5.22415 4.17679 5.41942C3.98152 5.61468 3.98152 5.93126 4.17679 6.12652L6.12133 8.07107L10.7175 3.47487Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
