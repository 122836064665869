import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconArrowDown: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3L6 9L1 3H11Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
