import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconChevronRight: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 2L8.5 6L4.5 10"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="square"
      />
    </svg>
  </span>
);
