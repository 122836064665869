import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconDashboard: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2C1.5 1.72386 1.72386 1.5 2 1.5H5C5.27614 1.5 5.5 1.72386 5.5 2V10C5.5 10.2761 5.27614 10.5 5 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10V2ZM7.5 2C7.5 1.72386 7.72386 1.5 8 1.5H10C10.2761 1.5 10.5 1.72386 10.5 2V4C10.5 4.27614 10.2761 4.5 10 4.5H8C7.72386 4.5 7.5 4.27614 7.5 4V2ZM7.5 7C7.5 6.72386 7.72386 6.5 8 6.5H10C10.2761 6.5 10.5 6.72386 10.5 7V10C10.5 10.2761 10.2761 10.5 10 10.5H8C7.72386 10.5 7.5 10.2761 7.5 10V7Z"
        stroke={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
