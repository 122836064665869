import React, { FC, memo, useState } from 'react';
import ReactSelect, { components, ValueType } from 'react-select';
import { Icon } from '../icon';
import classNames from 'classnames';

import style from './select.scss';
import { SelectType, selectOption } from 'types';

const CaretDownIcon = memo(() => <Icon name="arrow-down" />);

const ClearIcon = memo(() => <Icon name="cross" />);

const DropdownIndicator = memo((props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
});

const ClearIndicator = memo((props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
});

const ReactSelectMemo = memo(ReactSelect);

export const Select: FC<SelectType> = memo(
  ({
    name,
    value,
    label,
    onChange,
    onBlur,
    isDisabled,
    isRequired,
    isClearable,
    options,
    errorText,
    helperText,
  }) => {
    const [internalValue, setInternalValue] = useState(value);
    const [internalState, setInternalState] = useState({
      isMenuOpen: false,
    });

    const handleInternalChange = async (
      selectedOption: ValueType<selectOption>
      // actionMeta: ActionMeta<selectOption>
    ) => {
      setInternalValue(selectedOption);
      onChange &&
        (await onChange({ name, type: 'select', value: selectedOption }));
    };

    const handleInternalBlur = async (e: any) => {
      e.preventDefault();
      onBlur && (await onBlur({ name }));
    };

    const setMenuOpen = (value: boolean) => {
      setInternalState(state => ({
        ...state,
        isMenuOpen: value,
      }));
    };

    return (
      <label
        className={classNames(style.select, {
          [style['select--disabled']]: isDisabled,
          [style['select--required']]: isRequired,
          [style['select--error']]: errorText,
        })}
      >
        <ReactSelectMemo
          className={classNames(style['select__field'], {
            [style['select__field--has-value']]: internalValue,
            [style['select__field--open']]: internalState.isMenuOpen,
          })}
          components={{ DropdownIndicator, ClearIndicator }}
          classNamePrefix={'select'}
          isDisabled={isDisabled}
          isClearable={isClearable}
          name={name}
          options={options}
          onChange={handleInternalChange}
          onBlur={handleInternalBlur}
          value={internalValue}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          placeholder=""
        ></ReactSelectMemo>
        <span className={style['select__box']}></span>
        <span className={style['select__text']}>
          {label}
          {isRequired && <sup>*</sup>}
        </span>
        {errorText ? (
          <span className={style['select__helper']}>{errorText}</span>
        ) : (
          <span className={style['select__helper']}>{helperText}</span>
        )}
      </label>
    );
  }
);

Select.defaultProps = {
  value: null,
  children: null,
  isDisabled: false,
  isRequired: false,
  isClearable: false,
  onChange: () =>
    console.log(`Select has no change handler associated with it`),
  // onBlur: () =>
  //   console.log(`Select has no change handler associated with it`),
};
