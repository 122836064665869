import { useState, FormEvent, useCallback, useRef } from 'react';
import { getValidation } from './validations';

import './index.scss';
import {
  Value,
  useFormType,
  useFormState,
  onSubmitHandler,
  Name,
  Values,
  Error,
  Errors,
} from 'types';
import { OnChangeType, OnFocusType } from 'types/element';

export const useForm = <TValues extends Value = Value>({
  initialValues = {} as TValues,
}: useFormType<TValues>) => {
  const valueRef = useRef<TValues>(initialValues);
  const validationRef = useRef<TValues>({} as TValues);
  const errorRef = useRef<Errors<TValues>>({});
  const [internalState, setInternalState] = useState<useFormState<TValues>>({
    errors: {},
    touched: {},
    isSubmitting: false,
  });

  const onChange = useCallback(
    async ({ name, type, value, checked }: OnChangeType) => {
      if (name in valueRef.current) {
        valueRef.current = {
          ...valueRef.current,
          [name]: type === 'checkbox' ? checked : value,
        };
      }

      if (name in validationRef.current) {
        let validationErrors = await getValidation(
          type === 'checkbox' ? checked : value,
          validationRef.current[name],
          type === 'checkbox'
        );
        setInternalState((prevState: useFormState<TValues>) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [name]: validationErrors,
          },
        }));
      }
    },
    [setInternalState]
  );

  const onBlur = useCallback(
    async ({ name }: OnFocusType) => {
      // const target = evt.target;
      // const name = target.name;
      // evt.persist();
      setInternalState((prevState: useFormState<TValues>) => ({
        ...prevState,
        touched: {
          ...prevState.touched,
          [name]: true,
        },
      }));

      if (name in validationRef.current && name in valueRef.current) {
        let value: any = valueRef.current[name];
        let validation: any = validationRef.current[name];
        let validationErrors = await getValidation(value, validation);
        setInternalState((prevState: useFormState<TValues>) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [name]: validationErrors,
          },
        }));
      }
    },
    [setInternalState]
  );

  const onSubmit = useCallback(
    <TSubmit extends Value = TValues>(
      onValid: onSubmitHandler<TSubmit>
    ) => async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      let values: Values<TValues> = valueRef.current;

      setInternalState((prevState: useFormState<TValues>) => ({
        ...prevState,
        isSubmitting: true,
      }));

      // console.log(internalState);
      // DO something here
      // await onValid(values as TSubmit, e);
      await Object.keys(valueRef.current).forEach(
        async (name: keyof TValues) => {
          let value: any = valueRef.current[name];
          let validation: any = validationRef.current[name as string];
          let validationErrors = await getValidation(value, validation);
          errorRef.current[name] = validationErrors;
          // console.log(validationErrors);
          setInternalState((prevState: useFormState<TValues>) => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              [name]: validationErrors,
            },
          }));
        }
      );

      let inErrors: Errors<TValues> = errorRef.current;

      if (
        Object.keys(inErrors).every(
          (k: string) => Object.entries(inErrors[k] as Error).length === 0
        )
      ) {
        await onValid(values as TSubmit, e);
      }

      setInternalState((prevState: useFormState<TValues>) => ({
        ...prevState,
        isSubmitting: false,
      }));
    },
    []
  );
  const register = useCallback(
    (name: Name<TValues>, validations: Record<string, any>) => {
      // console.log(name, validations);

      if (name in valueRef.current) {
        validationRef.current = {
          ...validationRef.current,
          [name]: validations,
        };
      }

      // console.log('register validations here', validationRef.current);
    },
    []
  );

  const registeredCallback = useCallback(register, []);

  return {
    register: registeredCallback,
    values: valueRef.current,
    touched: internalState.touched,
    errors: internalState.errors,
    isSubmitting: internalState.isSubmitting,
    onChange,
    onBlur,
    onSubmit,
  };
};
