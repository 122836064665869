import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconHelp: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C7.34026 11 8.55736 10.4727 9.45512 9.61417C9.48222 9.58825 9.50903 9.56204 9.53553 9.53553C10.4404 8.63071 11 7.38071 11 6C11 4.63186 10.4505 3.39206 9.56016 2.48927L9.53553 2.46447C8.63071 1.55964 7.38071 1 6 1C4.61929 1 3.36929 1.55964 2.46447 2.46447C1.55964 3.36929 1 4.61929 1 6C1 7.37379 1.55405 8.61818 2.45089 9.52191L2.46447 9.53553C3.36929 10.4404 4.61929 11 6 11ZM3.17157 3.17157C2.44772 3.89543 2 4.89543 2 6H3.5C3.5 5.30964 3.77982 4.68464 4.23223 4.23223L3.17157 3.17157ZM6 3.5C6.69036 3.5 7.31536 3.77982 7.76777 4.23223L8.82843 3.17157C8.10457 2.44772 7.10457 2 6 2V3.5ZM4.23223 7.76777L3.17157 8.82843C3.89543 9.55229 4.89543 10 6 10V8.5C5.30964 8.5 4.68464 8.22018 4.23223 7.76777ZM7.76777 7.76777C8.22018 7.31536 8.5 6.69036 8.5 6H10C10 7.10457 9.55229 8.10457 8.82843 8.82843L7.76777 7.76777ZM6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6C7.5 6.82843 6.82843 7.5 6 7.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
