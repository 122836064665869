import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconMenu: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.5H10M2 8.5H10M2 6H10"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="round"
      />
    </svg>
  </span>
);
