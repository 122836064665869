import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconReport: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4V3.5V2H3V10H9V7.8033L10 6.8033V10C10 10.5523 9.55228 11 9 11H3C2.44772 11 2 10.5523 2 10V2C2 1.44772 2.44772 1 3 1H7H7.5H8L9.78033 2.78033L8.56066 4H7.5H7ZM8 2.41421L8.58579 3H8V2.41421ZM10.4445 4.94454C10.6398 4.74928 10.6398 4.4327 10.4445 4.23744C10.2493 4.04217 9.9327 4.04217 9.73744 4.23744L5.84835 8.12652L4.61091 6.88909C4.41565 6.69383 4.09907 6.69382 3.90381 6.88909C3.70854 7.08435 3.70854 7.40093 3.90381 7.59619L5.4948 9.18718L5.84835 9.54074L6.2019 9.18718L10.4445 4.94454Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
