import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconInbox: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.44772 1.44772 2 2 2H10C10.5523 2 11 2.44772 11 3V9C11 9.55228 10.5523 10 10 10H2C1.44772 10 1 9.55228 1 9V3ZM9.16667 3H2.83333L5.7 5.15C5.87778 5.28333 6.12222 5.28333 6.3 5.15L9.16667 3ZM2 3.625V9H10V3.625L6.9 5.95C6.36667 6.35 5.63333 6.35 5.1 5.95L2 3.625Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
