import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconArchive: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.44772 1.44772 2 2 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4V9C10 9.55228 9.55228 10 9 10H3C2.44772 10 2 9.55228 2 9L2 4C1.44772 4 1 3.55228 1 3ZM3 4H9V9H3V4ZM4.5 5C4.22386 5 4 5.22386 4 5.5C4 5.77614 4.22386 6 4.5 6H7.5C7.77614 6 8 5.77614 8 5.5C8 5.22386 7.77614 5 7.5 5H4.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
