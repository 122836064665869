import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconFile: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      data-v-1aa9d307=""
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 10 32 38"
    >
      <g
        data-v-1aa9d307=""
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          data-v-1aa9d307=""
          id="document-/-inactive"
          transform="scale(1.2, 1) translate(0 13)"
        >
          <path
            data-v-1aa9d307=""
            d="M23.75,8.14154759 L19.728869,5.72886899 C19.1306832,5.3699575 18.6300425,4.86931683 18.271131,4.27113101 L15.8584524,0.25 L4,0.25 C1.92893219,0.25 0.25,1.92893219 0.25,4 L0.25,32 C0.25,34.0710678 1.92893219,35.75 4,35.75 L20,35.75 C22.0710678,35.75 23.75,34.0710678 23.75,32 L23.75,8.14154759 Z"
            id="Rectangle"
            stroke={color}
            stroke-width="0.5"
            fill="#EBEBED"
          ></path>
          <path
            data-v-1aa9d307=""
            d="M0,13 L15,13 C15.5522847,13 16,13.4477153 16,14 L16,24 C16,24.5522847 15.5522847,25 15,25 L0,25 L0,25 L0,13 Z"
            id="Rectangle"
            fill="#FFFFFF"
          ></path>
          <path
            data-v-1aa9d307=""
            d="M15.5,-0.207106781 L24.2071068,8.5 L15.5,8.5 L15.5,-0.207106781 Z"
            id="Path-23"
            stroke={color}
            fill={color}
            stroke-linejoin="round"
          ></path>
        </g>
      </g>
    </svg>
  </span>
);
