import { ValidationRules, Message, Error } from 'types';
import { isEmail, required } from './functions';

export const getValidation = async (
  value: any,
  validations: ValidationRules,
  isCheckbox: boolean = false
): Promise<Error> => {
  let message: Message = '';

  //no validations then nothing to do here
  if (!validations) return {} as Error;

  if (validations.required) {
    if (typeof validations.required === 'object') {
      message = validations.required.message;
    } else if (typeof validations.required === 'string') {
      message = validations.required;
    }

    if (isCheckbox && !value) {
      return {
        type: 'required',
        message,
      };
    } else if (required(value)) {
      return {
        type: 'required',
        message,
      };
    }
  }

  if (validations.isEmail) {
    if (typeof validations.isEmail === 'object') {
      message = validations.isEmail.message;
    } else if (typeof validations.isEmail === 'string') {
      message = validations.isEmail;
    }
    if (!isEmail(value)) {
      return {
        type: 'email',
        message,
      };
    }
  }

  if (validations.validate) {
    if ((message = validations.validate(value) as string)) {
      return {
        type: 'validate',
        message,
      };
    }
  }

  return {} as Error;
};
