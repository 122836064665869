import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconDownload: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 10C1.22386 10 1 10.2239 1 10.5C1 10.7761 1.22386 11 1.5 11V10ZM10.5 11C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10V11ZM3.35355 5.64645C3.15829 5.45118 2.84171 5.45118 2.64645 5.64645C2.45118 5.84171 2.45118 6.15829 2.64645 6.35355L3.35355 5.64645ZM6 9L5.64645 9.35355C5.84171 9.54882 6.15829 9.54882 6.35355 9.35355L6 9ZM9.35355 6.35355C9.54882 6.15829 9.54882 5.84171 9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645L9.35355 6.35355ZM5.5 8.5C5.5 8.77614 5.72386 9 6 9C6.27614 9 6.5 8.77614 6.5 8.5H5.5ZM6.5 1.5C6.5 1.22386 6.27614 1 6 1C5.72386 1 5.5 1.22386 5.5 1.5H6.5ZM1.5 11H10.5V10H1.5V11ZM2.64645 6.35355L5.64645 9.35355L6.35355 8.64645L3.35355 5.64645L2.64645 6.35355ZM6.35355 9.35355L9.35355 6.35355L8.64645 5.64645L5.64645 8.64645L6.35355 9.35355ZM6.5 8.5V1.5H5.5V8.5H6.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
