import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconEdit: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3404 4.14017L4.85482 9.62573C4.80982 9.6763 4.75004 9.71555 4.67781 9.73577C4.66654 9.73896 4.65515 9.74162 4.64367 9.74374L2.14876 10.2427C1.88637 10.2952 1.65502 10.0639 1.7075 9.80146L2.20657 7.30612C2.20861 7.29517 2.21113 7.28429 2.21415 7.27353C2.23428 7.20075 2.2737 7.14055 2.32459 7.09531L7.81006 1.60984C7.95651 1.46339 8.19394 1.46339 8.34039 1.60984L10.3404 3.60984C10.4868 3.75628 10.4868 3.99372 10.3404 4.14017ZM9.07522 3.40533L9.5449 3.875L4.57523 8.84467L3.10556 7.375L8.07523 2.40533L8.5449 2.875L5.56006 5.85983C5.41361 6.00628 5.41361 6.24372 5.56006 6.39016C5.70651 6.53661 5.94394 6.53661 6.09039 6.39016L9.07522 3.40533ZM2.80552 8.13563L3.81459 9.1447L2.55325 9.39697L2.80552 8.13563Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
