import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconGrid: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="5"
        y="2"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="8"
        y="2"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="8"
        y="5"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="8"
        y="8"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="5"
        y="8"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="2"
        y="8"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="2"
        y="5"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
      <rect
        x="5"
        y="5"
        width="2"
        height="2"
        rx="0.5"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
