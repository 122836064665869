import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconMore: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4C6.55 4 7 3.55 7 3C7 2.45 6.55 2 6 2C5.45 2 5 2.45 5 3C5 3.55 5.45 4 6 4ZM6 5C5.45 5 5 5.45 5 6C5 6.55 5.45 7 6 7C6.55 7 7 6.55 7 6C7 5.45 6.55 5 6 5ZM6 8C5.45 8 5 8.45 5 9C5 9.55 5.45 10 6 10C6.55 10 7 9.55 7 9C7 8.45 6.55 8 6 8Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
