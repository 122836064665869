import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconBookmark: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1.5C2.72386 1.5 2.5 1.72386 2.5 2V10C2.5 10.2092 2.63029 10.3963 2.82653 10.4689C3.02277 10.5415 3.24346 10.4843 3.37963 10.3254L5.62037 7.71119C5.81992 7.47839 6.18008 7.47839 6.37963 7.71119L8.62037 10.3254C8.75654 10.4843 8.97723 10.5415 9.17347 10.4689C9.36971 10.3963 9.5 10.2092 9.5 10V2C9.5 1.72386 9.27614 1.5 9 1.5H3Z"
        stroke={color ? color : 'currentColor'}
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
