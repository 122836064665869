import React, { FC, memo, ReactElement } from 'react';
// import classnames from 'classnames';
import styles from './pw-strength.scss';

type PWStrengthType = {
  value: number;
};

export const PWStrength: FC<PWStrengthType> = memo(
  ({ value }): ReactElement => {
    return (
      <div className={styles['strength-meter']}>
        <div
          className={styles['strength-meter-fill']}
          data-strength={value}
        ></div>
      </div>
    );
  }
);
