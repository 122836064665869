import React, {
  FunctionComponent,
  ChangeEvent,
  FocusEvent,
  useState,
  MouseEvent,
  TouchEvent,
  memo,
} from 'react';
import classNames from 'classnames';

import { IconHidePassword } from '../icon/HidePassword';
import { IconShowPassword } from '../icon/ShowPassword';

import style from './input.scss';
import { PWStrength } from './pw-strength';
import { PasswordType } from 'types';

export const Password: FunctionComponent<PasswordType> = memo(
  ({
    name,
    value,
    label,
    onBlur,
    onChange,
    isDisabled,
    isRequired,
    errorText,
    helperText,
    strength,
  }) => {
    const [internalValue, setInternalValue] = useState(value);
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const handleInternalChange = async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const target = e.target;
      setInternalValue(e.target.value);
      onChange &&
        (await onChange({
          name: target.name,
          type: target.type,
          value: target.value,
        }));
    };

    const handleInternalBlur = async (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault();

      setInternalValue(e.target.value);
      onBlur && (await onBlur({ name: e.target.name }));
    };

    const changeType = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsPasswordShown(passShow => !passShow);
    };

    return (
      <label
        className={classNames(style.input, {
          [style['input--is-required']]: isRequired,
          [style['input--is-disabled']]: isDisabled,
          [style['input--error']]: errorText,
        })}
      >
        <input
          name={name}
          type={isPasswordShown ? 'text' : 'password'}
          className={classNames(style['input__field'], {
            [style['input__field--has-value']]: internalValue?.trim() !== '',
          })}
          value={internalValue}
          disabled={isDisabled}
          onChange={handleInternalChange}
          onBlur={handleInternalBlur}
        />
        <span className={style['input__box']}></span>
        <span className={style['input__text']}>
          {label}
          {isRequired && <sup>*</sup>}
        </span>
        {/* If password then show change icon and password strength meter, else toggle between helper text and error */}
        {
          <>
            <span
              className={style['input__show-password']}
              onClick={changeType}
              role="button"
              title={isPasswordShown ? 'Hide password' : 'Show password'}
            >
              {isPasswordShown ? <IconHidePassword /> : <IconShowPassword />}
            </span>

            {/* Add password strength meter here in the future */}
          </>
        }
        {typeof strength === 'number' && <PWStrength value={strength} />}
        {errorText ? (
          <span className={style['input__helper']}>{errorText}</span>
        ) : (
          <span className={style['input__helper']}>{helperText}</span>
        )}
      </label>
    );
  }
);

Password.defaultProps = {
  value: '',
  type: 'text',
  isDisabled: false,
  isRequired: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
};
