import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconUpload: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7L6 5L4 7"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.768 2.69804L7.20504 3.38665L7.96746 3.09699C8.13163 3.03462 8.31061 3 8.5 3C9.32843 3 10 3.67157 10 4.5C10 5.32843 9.32843 6 8.5 6H8.41421L8.70711 6.29289C8.88971 6.4755 8.98693 6.71115 8.99877 6.95024C10.1405 6.71908 11 5.70991 11 4.5C11 3.11929 9.88071 2 8.5 2C8.18738 2 7.88817 2.05738 7.61231 2.16218C7.16896 1.46363 6.38862 1 5.5 1C4.29031 1 3.28128 1.85919 3.04989 3.00061C3.03331 3.0002 3.01668 3 3 3C1.89543 3 1 3.89543 1 5C1 6.10456 1.89542 6.99999 2.99997 7C3.00003 6.73991 3.10158 6.48421 3.29289 6.29289L3.58579 6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4C3.00852 4 3.01699 4.0001 3.02542 4.00031L3.8634 4.02082L4.02995 3.1993C4.1687 2.51487 4.77534 2 5.5 2C6.0321 2 6.50035 2.27632 6.768 2.69804Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M6 6L6 10.5"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="round"
      />
    </svg>
  </span>
);
