import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconRefresh: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.62558 2.75039C8.8037 2.86913 8.8037 3.13087 8.62558 3.24962L6.46641 4.68906C6.26705 4.82197 6 4.67905 6 4.43944V1.56056C6 1.32095 6.26705 1.17803 6.46641 1.31094L8.62558 2.75039Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M6 2.5C5.20888 2.5 4.43552 2.7346 3.77772 3.17412C3.11992 3.61365 2.60723 4.23836 2.30448 4.96927C2.00173 5.70017 1.92252 6.50444 2.07686 7.28036C2.2312 8.05629 2.61216 8.76902 3.17157 9.32843C3.73098 9.88784 4.44372 10.2688 5.21964 10.4231C5.99556 10.5775 6.79983 10.4983 7.53074 10.1955C8.26164 9.89277 8.88635 9.38008 9.32588 8.72228C9.76541 8.06448 10 7.29113 10 6.5H9C9 7.09334 8.82406 7.67336 8.49441 8.16671C8.16477 8.66006 7.69623 9.04458 7.14805 9.27164C6.59987 9.4987 5.99667 9.55811 5.41473 9.44236C4.83279 9.3266 4.29824 9.04088 3.87868 8.62132C3.45912 8.20176 3.1734 7.66721 3.05765 7.08527C2.94189 6.50333 3.0013 5.90013 3.22836 5.35195C3.45543 4.80377 3.83994 4.33524 4.33329 4.00559C4.82664 3.67595 5.40666 3.5 6 3.5V2.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
