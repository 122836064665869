import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconHidePassword: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3536 2.35355C10.5488 2.15829 10.5488 1.84171 10.3536 1.64645C10.1583 1.45118 9.84171 1.45118 9.64645 1.64645L1.64645 9.64645C1.45118 9.84171 1.45118 10.1583 1.64645 10.3536C1.84171 10.5488 2.15829 10.5488 2.35355 10.3536L4.13735 8.56975C4.71302 8.82948 5.34165 9 6 9C8.76142 9 11 6 11 6C11 6 10.1156 4.81477 8.77284 3.93427L10.3536 2.35355ZM7.72309 4.98402L6.96613 5.74098C6.98822 5.82359 7 5.91042 7 6C7 6.55228 6.55228 7 6 7C5.91042 7 5.82359 6.98822 5.74098 6.96613L4.98402 7.72309C5.28177 7.89904 5.6291 8 6 8C7.10457 8 8 7.10457 8 6C8 5.6291 7.89904 5.28177 7.72309 4.98402Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M6 3C6.389 3 6.76762 3.05953 7.13107 3.16182L6.27425 4.01865C6.18459 4.00635 6.09304 4 6 4C4.89543 4 4 4.89543 4 6C4 6.09304 4.00635 6.18459 4.01865 6.27425L2.64613 7.64676C1.63514 6.85118 1 6 1 6C1 6 3.23858 3 6 3Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
