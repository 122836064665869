import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconLoading: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6C10.7761 6 11.0026 6.22443 10.975 6.4992C10.8737 7.50939 10.4665 8.46923 9.80203 9.24724C9.02885 10.1525 7.95803 10.7522 6.78217 10.9384C5.60631 11.1247 4.40259 10.8852 3.38751 10.2632C2.37243 9.64116 1.61261 8.67733 1.24472 7.54508C0.876829 6.41284 0.925013 5.18648 1.3806 4.08658C1.83619 2.98669 2.66929 2.08545 3.73005 1.54497C4.79081 1.00448 6.00961 0.860229 7.16723 1.13815C8.16211 1.377 9.05569 1.9142 9.73146 2.67189C9.91526 2.87798 9.86398 3.19265 9.64058 3.35497C9.41717 3.51728 9.10658 3.46515 8.91768 3.26372C8.3857 2.69646 7.697 2.29375 6.93378 2.11052C6.00768 1.88818 5.03264 2.00359 4.18404 2.43597C3.33543 2.86836 2.66896 3.58935 2.30448 4.46927C1.94001 5.34918 1.90146 6.33027 2.19577 7.23607C2.49009 8.14187 3.09794 8.91293 3.91001 9.41056C4.72207 9.90819 5.68505 10.0997 6.62574 9.95075C7.56643 9.80176 8.42308 9.32201 9.04163 8.59779C9.55138 8.00095 9.87184 7.27034 9.9688 6.49872C10.0032 6.22473 10.2239 6 10.5 6Z"
        fill="url(#paint0_angular)"
      />
      <defs>
        <radialGradient
          id="paint0_angular"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6 6) rotate(-40.6013) scale(4.60977 4.6898)"
        >
          <stop offset="0.511407" stop-color={color} stop-opacity="0.81" />
          <stop offset="0.88857" stop-color={color} stop-opacity="0" />
          <stop offset="0.987344" stop-color={color} />
        </radialGradient>
      </defs>
    </svg>
  </span>
);
