import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconUsers: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9C6 8.44772 6.44772 8 7 8H10C10.5523 8 11 8.44772 11 9V10H6V9Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M2 6C1.44772 6 1 6.44772 1 7V9H5.5V8.5C5.5 7.94772 5.94772 7.5 6.5 7.5H7V7C7 6.44772 6.55228 6 6 6H2Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M5.5 3.5C5.5 4.32843 4.82843 5 4 5C3.17157 5 2.5 4.32843 2.5 3.5C2.5 2.67157 3.17157 2 4 2C4.82843 2 5.5 2.67157 5.5 3.5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M9.75 6.25C9.75 6.94036 9.19036 7.5 8.5 7.5C7.80964 7.5 7.25 6.94036 7.25 6.25C7.25 5.55964 7.80964 5 8.5 5C9.19036 5 9.75 5.55964 9.75 6.25Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
