export const required = (value: string | number | boolean): boolean =>
  !!value ? false : true;

export const isEmail = (value: string): boolean =>
  value &&
  value.search(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  )
    ? false
    : true;

export const maxLength = (value: string, length: number): boolean =>
  value && value.length > length ? false : true;

export const minLength = (value: string, length: number): boolean =>
  value && value.length > length ? false : true;
