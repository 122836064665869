import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconHome: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 10.5V11H11V10.5H10.5ZM1.5 10.5H1V11H1.5V10.5ZM4.5 10.5V11H5V10.5H4.5ZM7.5 10.5H7V11H7.5V10.5ZM10.1644 4.70166L9.83218 5.07536L10.1644 4.70166ZM10 5.44907V10.5H11V5.44907H10ZM2 10.5V5.44907H1V10.5H2ZM2.16782 5.07536L5.66782 1.96425L5.00345 1.21684L1.50345 4.32795L2.16782 5.07536ZM6.33218 1.96425L9.83218 5.07536L10.4965 4.32795L6.99655 1.21684L6.33218 1.96425ZM4.5 10H1.5V11H4.5V10ZM10.5 10H7.5V11H10.5V10ZM4 7.5V10.5H5V7.5H4ZM8 10.5V7.5H7V10.5H8ZM6.5 6H5.5V7H6.5V6ZM8 7.5C8 6.67157 7.32843 6 6.5 6V7C6.77614 7 7 7.22386 7 7.5H8ZM5 7.5C5 7.22386 5.22386 7 5.5 7V6C4.67157 6 4 6.67157 4 7.5H5ZM5.66782 1.96425C5.85726 1.79586 6.14274 1.79586 6.33218 1.96425L6.99655 1.21684C6.42822 0.71166 5.57178 0.71166 5.00345 1.21684L5.66782 1.96425ZM11 5.44907C11 5.02061 10.8168 4.6126 10.4965 4.32795L9.83218 5.07536C9.93893 5.17025 10 5.30625 10 5.44907H11ZM2 5.44907C2 5.30625 2.06107 5.17025 2.16782 5.07536L1.50345 4.32795C1.18322 4.6126 1 5.02061 1 5.44907H2Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
