import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconBin: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2C1.22386 2 1 2.22386 1 2.5C1 2.77614 1.22386 3 1.5 3V2ZM10.5 3C10.7761 3 11 2.77614 11 2.5C11 2.22386 10.7761 2 10.5 2V3ZM4 8.5C4 8.77614 4.22386 9 4.5 9C4.77614 9 5 8.77614 5 8.5H4ZM5 4.5C5 4.22386 4.77614 4 4.5 4C4.22386 4 4 4.22386 4 4.5H5ZM6.5 4.5C6.5 4.22386 6.27614 4 6 4C5.72386 4 5.5 4.22386 5.5 4.5H6.5ZM5.5 8.5C5.5 8.77614 5.72386 9 6 9C6.27614 9 6.5 8.77614 6.5 8.5H5.5ZM7 8.5C7 8.77614 7.22386 9 7.5 9C7.77614 9 8 8.77614 8 8.5H7ZM8 4.5C8 4.22386 7.77614 4 7.5 4C7.22386 4 7 4.22386 7 4.5H8ZM3.5 11H8.5V10H3.5V11ZM10 9.5V3H9V9.5H10ZM3 9.5V3H2V9.5H3ZM8.5 11C9.32843 11 10 10.3284 10 9.5H9C9 9.77614 8.77614 10 8.5 10V11ZM3.5 10C3.22386 10 3 9.77614 3 9.5H2C2 10.3284 2.67157 11 3.5 11V10ZM1.5 3H4.5V2H1.5V3ZM5.5 2H6.5V1H5.5V2ZM7.5 3H10.5V2H7.5V3ZM4.5 3H7.5V2H4.5V3ZM6.5 2C6.77614 2 7 2.22386 7 2.5H8C8 1.67157 7.32843 1 6.5 1V2ZM5 2.5C5 2.22386 5.22386 2 5.5 2V1C4.67157 1 4 1.67157 4 2.5H5ZM5 8.5V4.5H4V8.5H5ZM5.5 4.5V8.5H6.5V4.5H5.5ZM8 8.5V4.5H7V8.5H8Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
