import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconCross: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 2.5L9.5 9.5M2.5 9.5L9.5 2.5"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="round"
      />
    </svg>
  </span>
);
