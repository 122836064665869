import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconUnits: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 3.91465C7.0826 3.70873 7.5 3.15311 7.5 2.5C7.5 1.67157 6.82843 1 6 1C5.17157 1 4.5 1.67157 4.5 2.5C4.5 3.15311 4.9174 3.70873 5.5 3.91465V5.5H3.5C2.67157 5.5 2 6.17157 2 7V8.08535C1.4174 8.29127 1 8.84689 1 9.5C1 10.3284 1.67157 11 2.5 11C3.32843 11 4 10.3284 4 9.5C4 8.84689 3.5826 8.29127 3 8.08535V7C3 6.72386 3.22386 6.5 3.5 6.5H5.5V8.08535C4.9174 8.29127 4.5 8.84689 4.5 9.5C4.5 10.3284 5.17157 11 6 11C6.82843 11 7.5 10.3284 7.5 9.5C7.5 8.84689 7.0826 8.29127 6.5 8.08535V6.5H8.5C8.77614 6.5 9 6.72386 9 7V8.08535C8.4174 8.29127 8 8.84689 8 9.5C8 10.3284 8.67157 11 9.5 11C10.3284 11 11 10.3284 11 9.5C11 8.84689 10.5826 8.29127 10 8.08535V7C10 6.17157 9.32843 5.5 8.5 5.5H6.5V3.91465ZM6 3.25C5.58579 3.25 5.25 2.91421 5.25 2.5C5.25 2.08579 5.58579 1.75 6 1.75C6.41421 1.75 6.75 2.08579 6.75 2.5C6.75 2.91421 6.41421 3.25 6 3.25ZM1.75 9.5C1.75 9.91421 2.08579 10.25 2.5 10.25C2.91421 10.25 3.25 9.91421 3.25 9.5C3.25 9.08579 2.91421 8.75 2.5 8.75C2.08579 8.75 1.75 9.08579 1.75 9.5ZM5.25 9.5C5.25 9.91421 5.58579 10.25 6 10.25C6.41421 10.25 6.75 9.91421 6.75 9.5C6.75 9.08579 6.41421 8.75 6 8.75C5.58579 8.75 5.25 9.08579 5.25 9.5ZM8.75 9.5C8.75 9.91421 9.08579 10.25 9.5 10.25C9.91421 10.25 10.25 9.91421 10.25 9.5C10.25 9.08579 9.91421 8.75 9.5 8.75C9.08579 8.75 8.75 9.08579 8.75 9.5Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
