import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconSearch: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5ZM2 5C2 6.65685 3.34315 8 5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M10.5 10.5L7.5 7.5"
        stroke={color ? color : 'currentColor'}
        strokeLinecap="round"
      />
    </svg>
  </span>
);
