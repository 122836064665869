import React, {
  FunctionComponent,
  ChangeEvent,
  FocusEvent,
  useState,
  useEffect,
  memo,
} from 'react';
import classNames from 'classnames';
import style from './checkbox.scss';
import { CheckboxType } from 'types';

export const Checkbox: FunctionComponent<CheckboxType> = memo(
  ({ name, value, onChange, onBlur, isDisabled, isRequired, children }) => {
    const [checked, setChecked] = useState(value);

    useEffect(() => {
      setChecked(value);
    }, [value]);

    const handleInternalChange = async (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      setChecked(target.checked);
      onChange &&
        (await onChange({
          name: target.name,
          type: target.type,
          checked: target.checked,
        }));
    };

    const handleInternalBlur = async (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault();

      setChecked(e.target.checked);
      onBlur && (await onBlur({ name: e.target.name }));
    };

    return (
      <label
        className={classNames(style.checkbox, {
          [style['checkbox--checked']]: checked,
          [style['checkbox--disabled']]: isDisabled,
          [style['checkbox--required']]: isRequired,
          [style['checkbox--error']]: children?.errorText,
        })}
      >
        <input
          type="checkbox"
          name={name}
          className={style['checkbox--invisible']}
          disabled={isDisabled}
          checked={checked}
          onChange={handleInternalChange}
          onBlur={handleInternalBlur}
          // {...props}
        />
        <span className={style['checkbox__checkbox']}>
          <svg width="2rem" height="2rem" viewBox="0 0 22 22">
            <g fill="none" fillRule="nonzero">
              <rect
                vectorEffect="non-scaling-stroke"
                x="1"
                y="1"
                rx="2"
                ry="2"
                width="20"
                height="20"
                fill="none"
                stroke="black"
                strokeWidth="2"
              />
              <polyline points="5.7,11.1 9.6,15.3 18,7.2" />
            </g>
          </svg>
        </span>
        {children?.label && (
          <span className={style['checkbox__label-text']}>
            {children?.label}
            {isRequired && <sup>*</sup>}
          </span>
        )}
        {children?.errorText ? (
          <span className={style['checkbox__helper']}>
            {children?.errorText}
          </span>
        ) : (
          <span className={style['checkbox__helper']}>
            {children?.helperText}
          </span>
        )}
      </label>
    );
  }
);

Checkbox.defaultProps = {
  value: false,
  isDisabled: false,
  isRequired: false,
};
