import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconShare: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49153 4.31325C7.85818 4.73406 8.39803 5 9 5C10.1046 5 11 4.10457 11 3C11 1.89543 10.1046 1 9 1C7.89543 1 7 1.89543 7 3C7 3.14371 7.01516 3.28388 7.04396 3.419L4.50847 4.68675C4.14182 4.26594 3.60197 4 3 4C1.89543 4 1 4.89543 1 6C1 7.10457 1.89543 8 3 8C3.60197 8 4.14182 7.73406 4.50847 7.31325L7.04396 8.581C7.01516 8.71612 7 8.85629 7 9C7 10.1046 7.89543 11 9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C8.39803 7 7.85818 7.26594 7.49153 7.68675L4.95604 6.419C4.98484 6.28388 5 6.14371 5 6C5 5.85629 4.98484 5.71612 4.95604 5.581L7.49153 4.31325ZM10 3C10 3.55228 9.55228 4 9 4C8.44772 4 8 3.55228 8 3C8 2.44772 8.44772 2 9 2C9.55228 2 10 2.44772 10 3ZM4 6C4 6.55228 3.55228 7 3 7C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5C3.55228 5 4 5.44772 4 6ZM9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
