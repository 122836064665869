import React, { FC, ReactElement } from 'react';
import { IconType } from './IconTypes';

export const IconFolder: FC<IconType> = ({
  height = 'auto',
  width = '100%',
  color,
}): ReactElement => (
  <span className="icon">
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.5L5.29289 2.79289C5.10536 2.60536 4.851 2.5 4.58579 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V8.5C1.5 9.05228 1.94772 9.5 2.5 9.5H9.5C10.0523 9.5 10.5 9.05228 10.5 8.5V5.5M6 3.5H9.5C10.0523 3.5 10.5 3.94772 10.5 4.5V5.5M6 3.5L8 5.5H10.5"
        stroke={color ? color : 'currentColor'}
      />
    </svg>
  </span>
);
