import React, {
  FunctionComponent,
  ChangeEvent,
  FocusEvent,
  useState,
  memo,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { InputType } from '../types';

import style from './input.scss';

export const Input: FunctionComponent<InputType> = memo(
  ({
    name,
    type,
    value,
    label,
    onBlur,
    onChange,
    isDisabled,
    isRequired,
    errorText,
    helperText,
  }) => {
    const [internalValue, setInternalValue] = useState(value);

    const handleInternalChange = async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const target = e.target;
      setInternalValue(e.target.value);
      onChange &&
        (await onChange({
          name: target.name,
          type: target.type,
          value: target.value,
        }));
    };

    const handleInternalBlur = async (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault();

      setInternalValue(e.target.value);
      onBlur && (await onBlur({ name: e.target.name }));
    };

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <label
        className={classNames(style.input, {
          [style['input--is-required']]: isRequired,
          [style['input--is-disabled']]: isDisabled,
          [style['input--error']]: errorText,
        })}
      >
        <input
          name={name}
          type={type}
          className={classNames(style['input__field'], {
            [style['input__field--has-value']]: internalValue?.trim() !== '',
          })}
          value={internalValue}
          disabled={isDisabled}
          onChange={handleInternalChange}
          onBlur={handleInternalBlur}
        />
        <span className={style['input__box']}></span>
        <span className={style['input__text']}>
          {label}
          {isRequired && <sup>*</sup>}
        </span>
        {errorText ? (
          <span className={style['input__helper']}>{errorText}</span>
        ) : (
          <span className={style['input__helper']}>{helperText}</span>
        )}
      </label>
    );
  }
);

Input.defaultProps = {
  value: '',
  type: 'text',
  isDisabled: false,
  isRequired: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
};
